






















































































































import { defineComponent, onMounted, provide, Ref, ref, watch } from '@vue/composition-api'
import { useState, useActions } from '@/shared/mixins/helpers'
import { useI18n } from 'vue-i18n-composable'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import moment from 'moment'
import Table from '@/shared/components/Table.vue'
import { TableHeaderDTO } from '@/dto/tableHeaderDTO'
import utils from '@/shared/mixins/utils'
import variables from '@/shared/variables'
import TaskRegister from '@/components/task-register/TaskRegister.vue'
import taskRegisterData from '@/assets/data/task-register-data.json'
import { TaskDTO } from '@/dto/backend-response/tasksDTO'
import taskQueries from '@/shared/queries/taskQueries'
import { exportTable } from '@/shared/services/TableExport'
import { useToast } from 'vue-toastification/composition'

export default defineComponent({
    components: { Table, TaskRegister },
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const tableHeaders: Ref<Array<TableHeaderDTO>> = ref([])
        const loading = ref(false)
        const { role } = useState(['role'])
        const { menuOpen } = useState(['menuOpen'])
        const { SET_OPEN_MENU } = useActions(['SET_OPEN_MENU'])
        const { SET_LEFT_MENU_SIDEBAR } = useActions(['SET_LEFT_MENU_SIDEBAR'])
        const toast = useToast()
        const taskList = ref([])
        const tableCmp = ref(null)
        const filterCount = ref(0)
        const filteredRecordCount = ref(0)
        const displayTaskDetails = ref(false)
        const lastRecordNo = ref(null)
        const taskDetailsRecordNo = ref(0)
        const searchValue = ref('')
        const taskId = ref(null)
        const totalPages = ref(0)
        const taskRegisterTitleInfomationOP = ref(null)
        const firstRecordNo = ref(null)
        const taskDropdownValues = ref({
            taskFrequencies: [],
            taskPriorities: [],
            taskStatuses: [],
            taskTypes: [],
            taskRepeatStrategies: [],
        })
        const statusInOrder = ref([])
        // providers
        provide('taskDropdownValues', taskDropdownValues.value)

        // open information overlay panel
        const toggleInformation = (event: object) => {
            taskRegisterTitleInfomationOP.value.toggle(event)
        }

        // get task dropdown values
        const getTaskDropdownValues = async () => {
            const query = `
                query{
                   taskValues{
                        ${taskQueries.TASK_DROPDOWN_VALUES}
                        error
                        status
                   } 
                } 
                `
            let result
            try {
                result = await apolloClient.getGraphqlData(query)
            } catch (err) {
                throw Error('Error while retrieving task dropdown values')
            }

            if (!(result.data.taskValues && result.data.taskValues.status)) {
                return
            }
            taskDropdownValues.value.taskFrequencies = result.data.taskValues?.taskFrequencies
            taskDropdownValues.value.taskPriorities = result.data.taskValues?.taskPriorities
            taskDropdownValues.value.taskStatuses = result.data.taskValues?.taskStatuses
            taskDropdownValues.value.taskTypes = result.data.taskValues?.taskTypes
            taskDropdownValues.value.taskRepeatStrategies = result.data.taskValues?.taskRepeatStrategies

            // store task status in a array to use in custom sort function (recieving order of the statuses from BE)
            const isLangNL = language.value === variables.LANGUAGES.DUTCH
            const status = taskDropdownValues.value.taskStatuses.map((status) =>
                isLangNL ? status.name_nl : status.name_en
            )
            statusInOrder.value = status
        }

        const setTableHeaders = () => {
            tableHeaders.value = [
                {
                    header: t('NUMBER_SHORT', language.value),
                    fieldName: 'refId',
                    style: 'min-width: 210px;',
                    sort: true,
                    alert: true,
                },
                {
                    header: t('TASK_LABEL_CONTROL_NUMBER', language.value),
                    fieldName: 'ctrNumber',
                },
                {
                    header: t('TASK_LABEL_MSR_NUMBER', language.value),
                    fieldName: 'msrNo',
                },
                {
                    header: t('TASK_LABEL_DESCRIPTION', language.value),
                    fieldName: 'description',
                    limit:200,
                },
                {
                    header: t('TASK_LABEL_OWNER', language.value),
                    fieldName: 'fowner',
                    sort: true,
                },
                {
                    header: t('TASK_LABEL_START_DATE', language.value),
                    fieldName: 'startDate',
                    type: 'date',
                    sort: true,
                },
                {
                    header: t('TASK_LABEL_END_DATE', language.value),
                    fieldName: 'endDate',
                    type: 'date',
                    sort: true,
                },
                {
                    header: t('TASK_LABEL_STATUS', language.value),
                    fieldName: 'taskStatus',
                    sort: true,
                },
                {
                    header: t('TASK_LABEL_PRIORITY', language.value),
                    fieldName: 'priority',
                    sort: true,
                },
                {
                    header: t('TASK_LABEL_EXECUTED_BY', language.value),
                    fieldName: 'fexecutedBy',
                    sort: true,
                },
            ]
        }

        const openTaskDetailsPopup = (id: number) => {
            displayTaskDetails.value = true
            taskId.value = id
            taskDetailsRecordNo.value = taskList.value.find((task) => task.id == id).no
            // check for the `tableCmp` reference existance
            if (!tableCmp.value) {
                return
            }
            // set last record no
            lastRecordNo.value = utils.getLastRecord(tableCmp.value.copyOfTableData)
            // set first record no
            firstRecordNo.value = tableCmp.value.copyOfTableData[0].no
        }

        // updating taskList array with additional fields and setting description content
        const toTableFormat = (tasks: Array<TaskDTO>) => {
            taskList.value = []
            tasks.map((task) => {
                let tr = {
                    no: null,
                    fowner: '',
                    taskStatus: null,
                    ctrNumber: '',
                    msrNo: '',
                    priority: '',
                    norm: '',
                    color: '',
                    info: '',
                    infoKey: '',
                    alertColorName: '',
                    fexecutedBy: '',
                    refId: '',
                    startDate: '',
                    endDate: '',
                    description:'',
                    
                }
                // creating a copy of object to hold formatted and extra attributes
                tr = Object.create(task)
                tr.no = utils.padLeft('000', task.refId)
                tr.fowner = task.owner && task.owner.firstName + ' ' + task.owner.lastName
                tr.fexecutedBy =
                    task.executedBy && task.executedBy.firstName + ' ' + task.executedBy.lastName
                tr.ctrNumber = task.relatedControl && task.relatedControl.controlNumber
                tr.msrNo = task.relatedMsr && task.relatedMsr.msrNumber
                tr.color = utils.getAlertInfo('', task).color
                tr.info = utils.getAlertInfo('', task).info
                tr.infoKey = utils.getAlertInfo('', task).infoKey
                tr.alertColorName = utils.getAlertInfo('', task).alertColorName
                tr.refId = task.refId
                tr.description = task.description
                tr.startDate = task.startDate
                tr.endDate = task.endDate

                // when task is related to control
                if (task.relatedControl) {
                    tr.norm = task.relatedNorm && task.relatedNorm.name
                } else {
                    // when task is related to MSR
                    tr.norm = task.relatedMsrNorm && task.relatedMsrNorm.name
                }
                if (task.taskPriority)
                    tr.priority =
                        language.value === variables.LANGUAGES.DUTCH
                            ? task.taskPriority.name_nl
                            : task.taskPriority.name_en
                if (task.status)
                    tr.taskStatus =
                        language.value === variables.LANGUAGES.DUTCH
                            ? task.status.name_nl
                            : task.status.name_en
                taskList.value.push(tr)
            })
            // set record counts
            filteredRecordCount.value = taskList.value.length
        }

        const getTaskList = async () => {
            loading.value = true
            const query = `
                query{
                    tasks{
                        items{
                            ${taskQueries.TASK_LIST}
                        }
                        status
                        error
                    }

                }
                
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(query)
                loading.value = false
            } catch (err) {
                loading.value = false
                throw Error('Error while retrieving tasks')
            }
            if (result) {
                const tasks = result.data.tasks.items
                // updating taskList with additional fields and setting description content
                toTableFormat(tasks)
            }
        }

        // go to task record
        const goToRecord = async (params) => {
            if (tableCmp.value) {
                /* get the index of selected details on `copyOfTableData`. 
            `taskList` cannot be use here as when the filter/ sort is in use, list is getting updated */
                const record = utils.getRecordByIndex(
                    tableCmp.value.copyOfTableData,
                    params.type,
                    params.no,
                    tableCmp.value
                )

                // use the id of the record to get next/previous details
                taskId.value = taskList.value.find((task) => task.no === record.no).id

                taskDetailsRecordNo.value = record.no
            }
        }

        // update list after task is created/updated
        const updateRecords = async () => {
            await getTaskList()
            // default sort by  `taskStatus`
            tableCmp.value && tableCmp.value.ascSortByTaskStatus('taskStatus')
        }

        const exportAllTasks = async () => {
            loading.value = true
            const date = new Date().toLocaleDateString()
            const fileName = `perium-tasks-${date}`
            const headers = [
                        ...tableHeaders.value,
                        { header: 'Alert Color', fieldName: 'alertColorName' },
                        { header: 'Alert Message', fieldName: 'alert' },
            ]
            const rows = taskList.value.map((row) => ({
                        ...row,
                        // Add field with translated alert message
                        alert: t(row.infoKey, language),
            }))
            try {
                await exportTable(
                    fileName,
                    headers,
                    rows
                )
                toast.success(t('TASK_EXPORT_SUCCESS_TOAST'))
            } catch (err) {
                toast.error(t('TASK_EXPORT_FAILED_TOAST'))
                // Re-throw error for Sentry
                // Cast Error to any because old typescript does not know about
                // the options parameter. Can be removed once typescript is updated.
                throw (Error as any)('Error while exporting controls', {
                    cause: err,
                })
            } finally {
                loading.value = false
            }
        }

        // display filter section
        const viewFilterSection = () => {
            tableCmp.value.setTableHeight()
        }

        // set filters count
        const setCount = (count: number) => {
            filterCount.value = count
        }

        // update table records count
        const updateRecordCount = (recordsCount: number) => {
            filteredRecordCount.value = recordsCount
        }

        // set searched records count
        const searchCount = (count: number) => {
            filterCount.value = 0
            filteredRecordCount.value = count
        }

        // close sidebar on escape key press
        const closeSidebarEsc = () => {
            // listening to escape key press
            document.addEventListener('keydown', (event) => {
                if (event.key === 'Escape') {
                    //close task register sidebar if it's open
                    if (displayTaskDetails.value) {
                        displayTaskDetails.value = false
                    }
                }
            })
        }

        // watch for language change from store and update table headers by calling setTableHeaders
        watch(
            language,
            () => {
                setTableHeaders()
            },
            {
                immediate: true,
            }
        )
        onMounted(async () => {
            closeSidebarEsc()
            getTaskDropdownValues()
            await getTaskList()
            // default sort by  `taskStatus`
            tableCmp.value && tableCmp.value.ascSortByTaskStatus('taskStatus')
        })

        return {
            t,
            moment,
            language,
            utils,
            tableHeaders,
            taskList,
            loading,
            variables,
            role,
            SET_OPEN_MENU,
            SET_LEFT_MENU_SIDEBAR,
            menuOpen,
            viewFilterSection,
            setCount,
            filterCount,
            filteredRecordCount,
            updateRecordCount,
            tableCmp,
            openTaskDetailsPopup,
            displayTaskDetails,
            lastRecordNo,
            taskDetailsRecordNo,
            goToRecord,
            searchValue,
            searchCount,
            getTaskList,
            taskRegisterData,
            taskId,
            totalPages,
            updateRecords,
            taskRegisterTitleInfomationOP,
            toggleInformation,
            firstRecordNo,
            statusInOrder,
            exportAllTasks,
        }
    },
})
